@import './themes';

@mixin nb-overrides() {
  nb-select.size-medium button {
    padding: 0.4375rem 2.2rem 0.4375rem 1.125rem !important;

    nb-icon {
      right: 0.41rem !important;
    }
  }

  nb-layout-header nav.fixed {
    padding: 0 !important;
  }

  nb-layout-footer nav {
    background-color: #4D4D4D !important;
    padding: 0 !important;

    a {
      color: #fff !important;
      font-family: LatoBold;
      font-size: 12px;
      text-decoration: none;
      text-transform: uppercase;

      &:hover > a {
        text-decoration: underline;
      }
    }
  }

  nb-tabset {
    .tabset {
      justify-content: left !important;
    }

    ul.tabset {
      li.tab[data-tab-id="refresh"] {
        margin-left: auto;

        a {
          color: var(--tabset-tab-text-color);
          text-transform: none;
        }
      }
    }
  }

  bw-schools-table ng2-smart-table th.ng2-smart-actions {
    width: 102px !important;
  }

  ng2-smart-table {
    &.nb-spinner-container {
      table {
        min-height: 300px;
      }

      td[colspan]::before {
        content: 'Loading...';
      }
    }

    td[colspan] {
      text-align: center;

      &::before {
        content: 'No data was found matching the current search criteria';
      }
    }

    th.chart {
      span {
        vertical-align: middle;
      }

      .arrived {
        display: inline-block;
        width: 25px;
        height: 14px;

        background-color: #28a745;

        &.not {
          background-color: #dc3545;
        }
      }
    }

    th {
      checkbox-filter {
        input {
          font-size: 0.3em !important;

          &:focus {
            box-shadow: none !important;
          }
        }

        a {
          display: none;
        }
      }

      .text-warning {
        filter: brightness(90%);
        font-size: 1.3em;
      }
    }
  }

  .btn-primary {
    background-color: #f1e103 !important;
    border-color: #dfd40a  !important;
    color: black !important;
    border-radius: 0px !important;
  }

  .btn-primary:hover {
    background-color: #f0c34d;
    border-color: #f0c34d;
    color: black;
    border-radius: 0px;
  }

  .btn-top-bar {
    padding-right: 15px;
    padding-left: 15px;
  }

  nb-select.appearance-filled.size-medium .select-button, .select-button.cdk-focused {
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-weight: normal;
  }

  bw-substitutions {
    .table th, .table td {
      padding: 0.5em;
      width:auto;
    }
    .filters-row {
      margin: 15px 0 15px 0;
      font-size: 16px;
      .select2-container {
        width: initial !important;
        min-width: 150px;
      }    
    }
  }

  bw-substitutions .form-control {
    width:auto;
    display:inline;
  }

  // suppress the date changer for visualization
  .fc .fc-toolbar.fc-header-toolbar {
    display: none;
  }

  // for accounts dashboard
  nb-tab .form-control {
    margin: 5px;
  }

  nb-card-body {
    min-height: 150px
  }


  .form-group {
    margin: 0;

    label {
      margin-right: 5px;
    }
  }

  .select2-container {
    width: 100% !important;
  }


  .select2-selection__rendered {
    line-height: 31px !important;
  }
  .select2-container .select2-selection--single {
    height: 35px !important;
  }
  .select2-selection__arrow, .select2-container .select2-selection--single .select2-selection__clear {
    height: 34px !important;
  }

  .select2-container .select2-selection--single .select2-selection__rendered {
    margin-left: 3px;
    font-size: 14px
  }

  textarea:focus, input:focus{
    outline: none;
  }


  // NbDialog overrides
  // TODO: fix naming and hard coded values
  .cdk-overlay-pane {
    nb-dialog-container {
      min-width: 400px;
    }
  }

  .cdk-overlay-pane.fullWidthDialog {
    width: 100%;

    nb-dialog-container {
      width: 100%;
      padding: 0 100px;
    }
  }

  .cdk-overlay-pane.fullWidthModal {
    width: 100%;

    nb-dialog-container {
      width: auto;

      margin-left: auto;
      margin-right: auto;
    }
  }

  // ngx-bootstrap/popover
  .popover {
    max-width: 600px;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 14px;
    font-weight: normal;
    line-height: 1.42857143;
    text-align: left;
  }

  .popover-content {
    max-height: 50em;
    overflow-y: auto;
    white-space: pre-wrap;
    padding: 9px 14px;
    line-height: 20px;
  }

  // select2
  .select2-dropdown {
    z-index: 10000;
  }
  
  .chart-container {
    width: 100%;
    display: flex;
    justify-content: center;
  }
  
  .echart-container {
    width: 100% !important;
    height: 400px;
  }
  
  .tab-buttons {
    margin-bottom: 10px;
    display: flex;
    justify-content: center;
    gap: 10px;
  }
  
  .tab-buttons button {
    padding: 8px 12px;
    border: none;
    cursor: pointer;
    background: #ddd;
    border-radius: 5px;
  }
  
  .tab-buttons button.active {
    background: #007bff;
    color: white;
  }
  
  .button-group {
    display: flex;
    align-items: center;
    gap: 8px; /* Adjust spacing between buttons */
  }
  .custom-badge {
    position: absolute !important;
    top: 4px; /* Moves the badge to the top */
    right: 4px; /* Moves the badge to the right */
    background-color: transparent !important;
    color: green !important;
    font-size: 14px; /* Adjust badge text size */
    font-weight: bold;
  }
  
  .p-button .p-button-icon {
    margin: 0 auto !important; /* Ensures icon stays centered */
  }

  .chart-container {
    width: 100%;
    height: 500px; /* Increase height for better spacing */
    overflow: visible; /* Prevent labels from being cut off */
  }
  
  .echart-container {
    width: 100%;
    height: 120%;
  }
  
}
