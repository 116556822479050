@import url('https://fonts.googleapis.com/css?family=Open+Sans:400,600,700&display=swap');

// themes - our custom or/and out of the box themes
@import 'themes';

// framework component themes (styles tied to theme variables)
@import '@nebular/theme/styles/globals';
@import '@nebular/auth/styles/globals';

@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/mixins';
@import 'bootstrap/scss/grid';

// loading progress bar theme
@import './pace.theme';

@import './overrides';

// install the framework and custom global styles
@include nb-install() {

  // framework global styles
  @include nb-theme-global();
  @include nb-auth-global();

  // loading progress bar
  @include ngx-pace-theme();

  @include nb-overrides();
}

@font-face {
  font-family: 'OpenSansSemibold';
  src: url('/assets/fonts/OpenSansSemibold.woff2') format('woff2'),
  url('/assets/fonts/OpenSansSemibold.woff') format('woff'),
  url('/assets/fonts/OpenSansSemibold.ttf') format('truetype'),
  url('/assets/fonts/OpenSansSemibold.svg#OpenSansSemibold') format('svg');
}

@font-face {
  font-family: 'LatoBold';
  src: url('/assets/fonts/LatoBold.eot');
  src: url('/assets/fonts/LatoBold.eot') format('embedded-opentype'),
  url('/assets/fonts/LatoBold.woff2') format('woff2'),
  url('/assets/fonts/LatoBold.woff') format('woff'),
  url('/assets/fonts/LatoBold.svg#LatoBold') format('svg');
}

@font-face {
  font-family: 'LatoRegular';
  src: url('/assets/fonts/LatoRegular.eot');
  src: url('/assets/fonts/LatoRegular.eot') format('embedded-opentype'),
  url('/assets/fonts/LatoRegular.woff2') format('woff2'),
  url('/assets/fonts/LatoRegular.woff') format('woff'),
  url('/assets/fonts/LatoRegular.svg#LatoRegular') format('svg');
}

@font-face {
  font-family: semibold;
  src: url('/assets/fonts/OpenSans-Semibold.ttf?#iefix') format('truetype');
}
